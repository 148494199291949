/**
 * Converts a var name (snake_case, camelCase, kebab-case) into display case.
 * Example: 'delinquent_macs_webhook_url' -> 'Delinquent Macs Webhook Url'
 * @param {string} text - The var name to convert.
 * @returns {string} - The converted display name.
 */
export const varNameToDisplayCase = (text: string): string => {
	return text
		.replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Handle camelCase
		.replace(/[_-]/g, ' ') // Handle snake_case and kebab-case
		.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
		.trim() // remove leading/trailing whitespace
		.split(' ') // split the string into words
		.map(word => word.toLowerCase()) // convert all words to lowercase
		.map(word => word.replace(/^\w/, c => c.toUpperCase())) // Capitalize the first letter of each word
		.join(' ') // join the words back together
}

/**
 * Converts a display name into a camelCased var name.
 * Example: 'Delinquent Macs Webhook Url' -> 'delinquentMacsWebhookUrl'
 * @param {string} text - The display name to convert.
 * @returns {string} - The converted var name.
 */
export const displayNameToCamelCase = (text: string): string => {
	return text
		.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
		.trim() // remove leading/trailing whitespace
		.split(' ') // split the string into words
		.map(word => word.toLowerCase()) // convert all words to lowercase
		.map((word, index) => index === 0 ? word : word.replace(/^\w/, c => c.toUpperCase())) // Capitalize the first letter of each word except the first
		.join('') // join the words back together
}
